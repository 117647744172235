import { RenderType, responsiveVal } from '@graphcommerce/next-ui'
import { productListRenderer } from '../ProductListItems/productListRenderer'
import { ProductCardSkeleton } from '../Skeleton/ProductCardSkeleton'
import { MostViewedGroupFragment } from './MostViewedGroup.gql'

type RelatedProductGroupProps = MostViewedGroupFragment & {
  classes?: {
    list?: string
  }
  displayOnMinicart?: boolean
  block_title?: string | null | undefined
  product_uid?: string
  loading?: boolean
}

export function RelatedProductGroup(props: RelatedProductGroupProps) {
  const { items, block_title, classes, displayOnMinicart, product_uid, loading } = props

  if (!items || items.length === 0) return null

  return (
    <div className={`flex w-full items-center justify-center ${displayOnMinicart ? 'mt-25' : ''}`}>
      <div
        className={`w-full max-w-maxContentWidth md:mb-5 ${
          displayOnMinicart ? 'mx-10' : 'mx-[20px]'
        }`}
      >
        {block_title && (
          <div
            className={`relative flex items-center py-5 lg:mx-0 ${displayOnMinicart ? 'mb-5' : ''}`}
          >
            <span
              className={` text-black ${
                displayOnMinicart
                  ? 'md:Type-XXXL-Regular Type-XL-Regular'
                  : 'Type-XXL-Regular lg:Type-H4-Regular md:Type-XXXL-Regular sm:italic'
              } `}
            >
              {block_title}
            </span>
          </div>
        )}
        <div className={`grid grid-cols-2 gap-[20px] md:gap-[20px] ${classes?.list || ''}`}>
          {items?.map((item) =>
            item ? (
              <div className='col-span-1' key={item.sku}>
                <RenderType
                  key={item.uid ?? ''}
                  renderer={productListRenderer}
                  sizes={responsiveVal(200, 400)}
                  titleComponent='h3'
                  columns={6}
                  {...item}
                />
              </div>
            ) : null,
          )}
        </div>
      </div>
    </div>
  )
}
